<template>
	<v-dialog v-model="dialog" persistent max-width="900px">
		<v-card>
			<!-- Header -->
			<v-card-title class="d-flex justify-space-between border-bottom">
				<span class="subtitle-1">Добавление в белый список</span>
				<v-btn color="dark" @click="closeModal" icon>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>

			<!-- Progress bar -->
			<v-progress-linear
				v-if="loading"
				color="primary"
				height="5"
				indeterminate
			></v-progress-linear>

			<!-- Body -->
			<v-card-text v-else>
				<v-container>
					<v-form ref="form">
						<v-row>
							<!-- Въезд -->
							<v-col cols="12" sm="6" md="4">
								<span class="subtitle-2 font-weight-light">
									Въезд
								</span>
								<v-autocomplete
									v-model="data.entry_id"
									class="border-all mt-2 custom-text-field"
									solo
									dense
									hide-details
									flat
									:items="entries"
									item-text="name"
									item-value="_id"
									required
								></v-autocomplete>
							</v-col>
							<!-- Номер телефона -->
							<v-col cols="12" sm="6" md="4">
								<span class="subtitle-2 font-weight-light">
									Номер телефона
								</span>
								<v-text-field
									v-model="data.phone"
									:placeholder="phoneCode+'-'"
									v-mask="phoneCode == '+7' ? phoneCode+'-###-###-##-##' : phoneCode+'-###-###-####'"
									class="border-all mt-2 custom-text-field"
									solo
									dense
									hide-details
									flat
									required
								></v-text-field>
							</v-col>
							<!-- Гос. номер -->
							<v-col cols="12" sm="6" md="4">
								<span class="subtitle-2 font-weight-light">
									Гос. номер
								</span>
								<v-text-field
									v-model="data.car_number"
									class="border-all mt-2 custom-text-field"
									solo
									dense
									hide-details
									flat
									required
								></v-text-field>
							</v-col>
							<!-- Адрес -->
							<v-col cols="12" sm="6" md="4">
								<span class="subtitle-2 font-weight-light">
									Адрес
								</span>
								<v-autocomplete
									v-model="data.house_id"
									class="border-all mt-2 custom-text-field"
									solo
									dense
									hide-details
									flat
									:filter="addressFilter"
									:items="houses"
									item-text="apartment_address"
									item-value="_id"
									required
								></v-autocomplete>
							</v-col>
							<!-- Паркоместо -->
							<v-col cols="12" sm="6" md="4">
								<span class="subtitle-2 font-weight-light">
									Паркоместо
								</span>
								<v-text-field
									v-model="data.parking_place"
									class="border-all mt-2 custom-text-field"
									solo
									dense
									hide-details
									flat
									required
								></v-text-field>
							</v-col>
							<!-- Квартира -->
							<v-col cols="12" sm="6" md="4">
								<span class="subtitle-2 font-weight-light">
									Квартира
								</span>
								<v-text-field
									v-model="data.apartment_address"
									class="border-all mt-2 custom-text-field"
									solo
									dense
									hide-details
									flat
									required
								></v-text-field>
							</v-col>
							<!-- Alert -->
							<v-col cols="12" class="py-0">
								<v-alert
									v-if="alert"
									class="mb-0"
									:type="alert.color"
									:color="alert.color"
									outlined
									dense
								>
									{{ alert.message }}
								</v-alert>
							</v-col>
						</v-row>
					</v-form>
				</v-container>
			</v-card-text>
			<v-card-actions class="pt-0 pb-5 d-flex justify-end">
				<v-btn
					v-if="!toEdit"
					class="subtitle-2 text-capitalize"
					color="primary"
					depressed
					:loading="isAdding"
					@click="addToWhitelist"
				>
					Добавить
				</v-btn>
				<div v-else class="d-flex justify-end">
					<!-- Удалить -->
					<v-btn
						class="subtitle-2 text-capitalize mr-2"
						color="red white--text"
						depressed
						:loading="isDeleting"
						@click="deleteFromWhitelist"
					>
						Удалить
					</v-btn>
					<!-- Сохранить -->
					<v-btn
						class="subtitle-2 text-capitalize"
						color="primary"
						depressed
						:loading="isSaving"
						@click="editWhitelistEntry"
					>
						Сохранить
					</v-btn>
				</div>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "WhitelistTableModal",
	props: {
		toEdit: {
			type: Object,
		},
	},
	data() {
		return {
			alert: null,
			data: {
				phone: "",
				car_number: "",
				house_id: "",
				entry_id: this.$route.params.id,
				parking_place: "",
				apartment_address: "",
			},
			dialog: true,
			loading: true,
			isAdding: false,
			isDeleting: false,
			isSaving: false,
		};
	},
	computed: {
		...mapGetters({
			entries: "qr/GET_ENTRIES",
			houses: "ksk/GET_HOUSES",
			role: "auth/GET_USER_ROLE_VUEX",
			phoneCode: "auth/GET_USER_PHONE_CODE",
		}),
	},
	watch: {
		alert(val) {
			if (!val) return;
			setTimeout(() => {
				this.alert = null;
			}, 3000);
		},
	},
	methods: {
		// Валидация
		customValidation() {
			let message;

			if (!this.data.phone) {
				message = "Укажите номер телефона";
			} else if (!this.data.car_number) {
				message = "Укажите гос. номер";
			} else if (!this.data.house_id) {
				message = "Укажите адрес";
			} else if (!this.data.parking_place) {
				message = "Укажите паркоместо";
			}

			if (message) {
				this.alert = {
					color: "error",
					message,
				};
				return false;
			}

			return true;
		},
		// Добавление в белый список
		async addToWhitelist() {
			this.alert = null;

			if (!this.customValidation()) return;

			this.isAdding = true;

			try {
				let res;

				res = await this.$api.ksk.add_to_whitelist(this.data);

				this.alert = {
					color: "success",
					message: res.success || "Пользователь добавлен в белый лист",
				};

				this.$refs.form.resetValidation();
				this.$emit("update-info");
			} catch (error) {
				this.alert = {
					color: "error",
					message: error.message || "Произошла ошибка. Повторите еще раз.",
				};
			} finally {
				this.isAdding = false;
			}
		},
		// Редактирование
		async editWhitelistEntry() {
			this.alert = null;

			if (!this.customValidation()) return;

			try {
				this.isSaving = true;

				const res = await this.$api[this.role].edit_whitelist_entry({
					id: this.data._id,
					data: this.data,
				});

				this.alert = {
					color: "success",
					message: res.success || "Информация обновлена",
				};

				this.$emit("update-info");
			} catch (error) {
				console.log(error);
			} finally {
				this.isSaving = false;
			}
		},
		// Удаление из белого списка
		async deleteFromWhitelist() {
			this.alert = null;

			if (!this.customValidation()) return;

			try {
				this.isDeleting = true;

				const res = await this.$api[this.role].delete_from_whitelist(
					this.data._id
				);

				this.alert = {
					color: "success",
					message: res.success || "Пользователь удален из белого листа",
				};
				this.$emit("update-info");
				this.closeModal();
			} catch (error) {
				console.log(error);
			} finally {
				this.isDeleting = false;
			}
		},

		closeModal() {
			this.dialog = false;
			this.$emit("close-modal");
		},
	},

	async created() {
		try {
			this.loading = true;
			if (this.toEdit) {
				this.data = {
					...this.data,
					...this.toEdit,
					entry_id: this.toEdit.entry._id,
					house_id: this.toEdit.house._id,
				};
				console.log(this.data);
			}
			if (this.$route.query.entry_id) {
				this.data.entry_id = this.$route.query.entry_id;
			}
		} catch (error) {
			console.error(error);
		} finally {
			this.loading = false;
		}
	},
};
</script>

<style lang="scss" scoped></style>
