<template>
	<v-dialog v-model="dialog" persistent max-width="600">
		<v-card>
			<v-card-title class="d-flex justify-space-between border-bottom mb-3">
				<div class="d-flex">
					<span class="font-weight-light subtitle-2 mr-2">
						{{ title }}
					</span>
				</div>
				<v-btn color="dark" @click="closeModal" icon>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<span class="subtitle-2 font-weight-light mb-3">
					<slot>
						{{ text }}
					</slot>
					<slot name="text"></slot>
				</span>
				<v-textarea
					v-model="comment"
					class="border-all"
					height="100px"
					no-resize
					solo
					flat
					dense
				></v-textarea>
			</v-card-text>
			<v-card-actions class="d-flex justify-end">
				<v-btn
					class="text-capitalize subtitle-2"
					color="red darken-1 white--text"
					depressed
					:disabled="disabled"
					@click="closeModalTrue"
				>
					{{ btnText || "Отклонить" }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "ModalWarning",
	props: {
		text: {
			type: String,
			default: "",
		},
		title: {
			type: String,
			required: true,
		},
		btnText: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			comment: "",
			dialog: true,
		};
	},
	computed: {
		disabled() {
			return this.comment.length < 1;
		},
	},
	methods: {
		/**
		 * Вернуть true, если дейтсвие подвтерждено
		 */
		closeModal() {
			this.dialog = false;
			this.$emit("close-modal", false);
		},
		closeModalTrue() {
			this.dialog = false;
			this.$emit("close-modal", true, this.comment);
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .v-text-field {
	&:focus-within {
		border-color: #77dc99 !important;
		box-shadow: 0px 0px 4px #81e9a3;
	}
}
::v-deep .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
	min-height: 33px !important;
	font-size: 14px !important;
}
::v-deep .v-text-field__details {
	display: none !important;
}
</style>
